import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment-timezone';
import { BaseViewComponent } from 'src/app/components/base/base-view/base-view.component';
import { DatumViewModel } from 'src/app/models/datum_model';
import { MappingViewModel } from 'src/app/models/mapping_model';
import { UserViewModel } from 'src/app/models/user_model';
import { ScrollingService } from 'src/app/services/scrolling.service';
import * as constants from 'src/app/utils/constants';
import * as formatter from 'src/app/utils/formatter';
import { DeviceViewModel } from 'src/app/models/device_model';
import { GroupViewModel } from 'src/app/models/group_model';

@Component({
  selector: 'report-daily-view',
  templateUrl: './report-daily-view.component.html',
  styleUrls: ['./report-daily-view.component.scss']
})
export class ReportDailyViewComponent extends BaseViewComponent implements OnInit {

  max = Math.max;

  @Input('userId')
  userId: string;

  @Input('user')
  user: UserViewModel;

  @Input('mappings')
  mappings: Array<MappingViewModel>;

  @Input('selectedMapping')
  selectedMapping: MappingViewModel;

  @Input('selectedDate')
  selectedDate: moment.Moment;

  @Input('selectedTarget')
  selectedTarget: DeviceViewModel | GroupViewModel;

  @Input('dayDatum')
  dayDatum: DatumViewModel;

  @Input('dayShifts')
  dayShifts: DatumViewModel[];

  @Input('dayBeforeDatum')
  dayBeforeDatum: DatumViewModel;

  @Input('dayBeforeShifts')
  dayBeforeShifts: DatumViewModel[];

  @Input('bannerText')
  bannerText: string;

  @Input('inProgressCounter')
  inProgressCounter: number;

  @Input('unitDetails')
  unitDetails: {
    volume: number,
    time: number,
    symbols: {
      volume: string,
      time: string,
      flowrate: string,
    }
  }

  @Output('mappingChangeClick')
  mappingChangeClick = new EventEmitter<MappingViewModel>();

  @Output('dateChangeClick')
  dateChangeClick = new EventEmitter<any>();

  @Output('resetClick')
  resetClick = new EventEmitter<boolean>();

  today = new Date();
  dailyShifts: any[];
  comparisonGauseColorScheme = { domain: [constants.getCssVariable('--primary-color')] };
  shiftChartColorScheme = { domain: ['#31C3DF', '#FFD47A', '#DC189C'] };
  shiftChartCumulativeData: Array<{ name: string, series: Array<{name: string, value: number}>}>;

  constructor(
    scrollingService: ScrollingService,
    breakpointObserver: BreakpointObserver,
    snackBar: MatSnackBar,
  ) {
    super(scrollingService, breakpointObserver, snackBar);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if(this.uiState !== this.ViewUiState.data) return;
    
    // Calculate data for reading comparison chart
    this.shiftChartCumulativeData = [];
    let shiftCumulative = 0;
    let series = this.dayBeforeShifts.map(s => {
      const timing = moment(s.end_time).format('hh:mm a');
      shiftCumulative = shiftCumulative + s.net_volume;
      return { name: timing, value: shiftCumulative * this.unitDetails.volume};
    });
    series = [ {name: '00:00 am', value: 0} , ...series]
    this.shiftChartCumulativeData.push({ name: this.selectedDate.clone().subtract(1, 'day').format('MMM DD'), series: series});
    
    shiftCumulative = 0;
    series = this.dayShifts.map(s => {
      const timing = moment(s.end_time).format('hh:mm a');
      shiftCumulative = shiftCumulative + s.net_volume;
      return { name: timing, value: shiftCumulative * this.unitDetails.volume};
    });
    series = [ {name: '00:00 am', value: 0} , ...series]
    this.shiftChartCumulativeData.push({ name: this.selectedDate.format('MMM DD'), series: series});
    
    // Calculate data for number cards
    let shiftNotStarted = false;
    let shiftCounter = 1;
    
    // Set up day boundaries for shift filtering
    let dayStart = this.selectedDate.clone().startOf('day').subtract(1, 'day');
    let dayEnd = this.selectedDate.clone().endOf('day'); // Changed to endOf('day') to include the full day
    
    const shifts = this.selectedTarget.hardwareShifts;
    if(shifts){
      const shift = shifts.split(",");
      const [hour, min] = shift[0].split(":");
      const shiftStartHour = parseInt(hour);
      
      // Adjust dayStart and dayEnd based on shift start time
      dayStart = dayStart.clone().startOf('day').add(shiftStartHour, 'h').add(min, 'm').add(1, 's');
      
      // For late shifts (like 22:00), we need to ensure the next day's shifts are included
      if (shiftStartHour >= 12) {
        // If shift starts in the evening, extend dayEnd to include shifts that end the next day
        dayEnd = this.selectedDate.clone().add(1, 'day').add(shiftStartHour, 'h').add(min, 'm');
      } else {
        dayEnd = this.selectedDate.clone().add(shiftStartHour, 'h').add(min, 'm');
      }
    }
  
    // Filter and format shift data for display
    // Include shifts that start within our day range OR end within our day range
    this.dailyShifts = this.dayShifts
      .filter(s => {
        const shiftStartTime = moment(s.start_time);
        const shiftEndTime = moment(s.end_time);
        
        // Include shift if it overlaps with our day range
        return (shiftEndTime > dayStart && shiftEndTime <= dayEnd) || 
               (shiftStartTime >= dayStart && shiftStartTime < dayEnd);
      })
      .map(s => {
        let label = moment(s.start_time).add(5, 'minute').startOf('hour').format('hh:mm a');
        if(!this.mobileView) label = `Shift ${shiftCounter}: ${label}`;
        shiftCounter++;
        
        if(shiftNotStarted) {
          label = `${label} (Not Started)`;
        } else if(moment(s.end_time).isAfter(moment.tz('Asia/Kolkata'))) {
          label = `${label} (In Progress)`;
          shiftNotStarted = true;
        } else {
          label = `${label} (Completed)`;
        }
        
        return {
          name: label,
          value: s.net_volume,
        };
      });
  }

  async onClickMappingChange(mapping: MappingViewModel) {
    this.mappingChangeClick.emit(mapping);
  }

  async onClickDateChange(date: any) {
    this.dateChangeClick.emit(date);
  }

  onClickPrint() {
    // Open in new tab because window.print breaks flexbox styling
    // Flexbox breakpoints don't run! so fuck it, opening it in new tab
    window.open(`${window.location.pathname}/print${window.location.search}`, '_blank');
  }

  async onClickReset() {
    this.resetClick.emit(true);
  }

  public literValueFormatting = (data: any): string => {
    let num = (typeof(data) === 'number') ? data : data.value;
    return formatter.numberWithCommas(Math.round(num*this.unitDetails.volume*100)/100) + ' ' + this.unitDetails.symbols.volume;
  }
}
